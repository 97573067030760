import { ISwiperData, SwiperImageExtension } from '../../shared/interfaces/swiper.interfaces';

const carouselImagePathPrefix = 'assets/images/home-carousel';

const currentFormat = SwiperImageExtension.PNG;

export const CarouselElements: ISwiperData[] = [
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A35-5G-128GB-202406-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A35-5G-128GB-202406-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A35-5G-128GB-202406-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A35-5G-128GB-202406-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A35-5G-128GB-202406-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/keszulekek/Samsung%20Galaxy%20A35%205G',
      queryParams: {
        szolgaltatas: 110083345,
        husegIdo: 24,
        futamIdo: 0,
        szin: 0,
      },
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-408-202406-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-408-202406-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-408-202406-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-408-202406-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-408-202406-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/keszulekek/TCL%20408',
      queryParams: {
        szolgaltatas: 110083346,
        husegIdo: 24,
        futamIdo: 0,
        szin: 0,
      },
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-korlatlan-50GB-202406-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-korlatlan-50GB-202406-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-korlatlan-50GB-202406-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-korlatlan-50GB-202406-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-korlatlan-50GB-202406-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-korlatlan-100GB-202406-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-korlatlan-100GB-202406-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-korlatlan-100GB-202406-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-korlatlan-100GB-202406-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-korlatlan-100GB-202406-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
];
